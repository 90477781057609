import _ from 'lodash';
import {mathAbs} from "bootstrap-vue/esm/utils/math";
import { BIconChevronExpand} from "bootstrap-vue";
import { BIconCaretRightFill } from "bootstrap-vue"
const VueTimepicker = () => import('vue2-timepicker');
import 'vue2-timepicker/dist/VueTimepicker.css';
import isEmail from "validator/es/lib/isEmail";
const PriceList = () => import("../PriceList/PriceList.vue");

export default {
    props: ['modal_id', 'prices'],
    components: { BIconChevronExpand, BIconCaretRightFill, VueTimepicker, PriceList },
    created() {
        this.getBookings();
        this.getHouseRules();
        this.getBookingConditions();
    },
    mounted() {
        let waitRef = setInterval(() => {
            if (!this.$refs.dpRef) {
                return;
            }
            this.$watch(
              () => {
                  return this.$refs.dpRef.isDragging;
              },
              (val) => {
                  this.dateSelectStarted = val;
              }
            );
            clearInterval(waitRef);
        }, 100);
    },
    watch: {
        'bookingDate': function(newValue, oldValue) {
            if (newValue === undefined || oldValue === undefined || this.statusClosing) {
                return;
            }
            this.getQuote();
        },
        'campaignCode': function () {
            this.searchingQuote = true;
            this.getCampaign();
        }
    },
    data() {
        return {
            firstName: '',
            statusClosing: false,
            lastName: '',
            email: '',
            agreedReservationRules: false,
            agreedHouseRules: false,
            barrelSaunaCount: 0,
            phoneNumber: '',
            maxDateByConfig: this.$moment(new Date()).add(parseInt(this.$root.appConfig.maxBookingDays), 'd').toDate(),
            defaultCalendarAttribs: [{
                key: 'today',
                bar: 'red',
                dates: new Date(),
                popover: {
                    label: this.$i18n.t('Today')
                },
            }],
            currentBookings: [],
            bookingsLoaded: false,
            bookingDate: {
                start: undefined,
                end: undefined,
            },
            quote: {
                totalAmount: 0,
                details: [],
            },
            campaignCode: undefined,
            arrivalTime: "15:00",
            minArrivalTime: parseInt(this.$root.appConfig.minArrivalTime) || "15",
            peoplesCount: 0,
            peoplesMaxCount: this.$root.appConfig.maxPeoples || 6,
            showReservationRules: false,
            showHouseRules: false,
            dateSelectStarted: false,
            dateStartedDate: undefined,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            timeConfig: {

            },
            houseRules: undefined,
            bookingConditions: undefined,
            priceList: undefined,
            useRecaptcha: this.$root.appConfig.useRecaptcha !== undefined ? this.$root.appConfig.useRecaptcha === "1" : false,
            reCaptchaVerified: false,
            reCaptchaToken: '',
            reCaptchaSiteKey: this.$root.appConfig.recaptchaSiteKey || undefined,
            timeRemaining: 0,
            stopTimer: false,
            showDiscount: false,
            searchingQuote: false,
            dblCounter: 0,
            timeZone: 'Europe/Tallinn'
        }
    },
    methods: {
        fillInitialValues() {
            if (this.firstName !== undefined && this.firstName.trim().length) {
                console.log('Should populate first name');
            }
        },
        exitBooking() {
            this.statusClosing = true;
            this.clearFields();
            this.$bvModal.hide(`${this.modal_id}`);
        },
        getBookings() {
            let url = '/activebookings';
            let that = this;
            this.$http().get(url)
                .then((response) => {
                    let bookedDates = [];
                    response.data.forEach((item) => {
                        bookedDates.push({
                            start: that.$moment(item.bookingStart, that.modelConfig.mask).toDate(),
                            end: that.$moment(item.bookingEnd, that.modelConfig.mask).toDate(),
                        });
                    });
                    that.currentBookings = bookedDates;
                    that.bookingsLoaded = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.open({
                        type: 'error',
                        message: 'Error getting current bookings',
                        duration: 4000,
                    });
                    this.$root.appConfig.allowBooking = false;
                })
        },
        getCampaign: _.debounce(function() {
            this.getQuote();
        }, 1000),
        getQuote() {
            let cookie = this.$cookies.get("x-csrf-token");
            let url = "/getquote";
            let data = {
                barrelSaunaCount: this.barrelSaunaCount || 0,
                fromDate: this.bookingDate.start,
                toDate: this.bookingDate.end,
                campaignCode: this.campaignCode,
                lang: this.$i18n.locale,
                _csrf: cookie,
            };
            this.searchingQuote = true;

            this.$http().post(url, data)
                .then((response) => {
                    if (response.data) {
                        this.quote = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.open({
                        type: 'error',
                        message: this.$i18n.t('Error getting a quote'),
                        duration: 4000,
                    });
                })
              .finally(() => {
                  this.searchingQuote = false;
              })
        },
        confirmBooking() {
            try {
                this.validateInput();
            } catch (e) {
                this.$toast.open({
                    type: 'error',
                    message: e.message,
                    duration: 4000,
                });
                return;
            }

            let options = {
                html: true,
                okText: this.$i18n.t("Yes"),
                cancelText: this.$i18n.t("Cancel"),
            };
            this.$dialog
                .confirm({
                    title: `<b>${this.$i18n.t('Confirm reservation')}</b>`,
                    body: `<p style="margin-top: 24px;">${this.$i18n.t('Confirm reservation accordingly to selected data and amount')} <strong> ${this.bookingAmount} €</strong>?</p>`,
                }, options)
                .then(this.bookNow)
                .catch(() => {
                    console.log("Pressed cancel");
                })
        },
        validateInput() {
            if (!isEmail(this.email)) {
                throw new Error(this.$i18n.t('Email is in wrong format').toString());
            }
            if (this.$root.appConfig === undefined || !this.$root.appConfig.allowBooking) {
                throw new Error(this.$i18n.t('Booking is not allowed at the moment').toString());
            }
            if (this.numOfNights < 1) {
                throw new Error(this.$i18n.t('Please select the booking dates').toString());
            }
            if (this.peoplesCount < 1) {
                throw new Error(this.$i18n.t('Select number of people').toString());
            }
            if (this.firstName.trim().length < 1) {
                throw new Error(this.$i18n.t('Enter first name').toString());
            }
            if (this.lastName.trim().length < 1) {
                throw new Error(this.$i18n.t('Enter surname').toString());
            }
            if (this.phoneNumber.trim().length < 1) {
                throw new Error(this.$i18n.t('Enter phone number').toString());
            }
        },
        bookNow() {
            let url = "/makebooking";
            let cookie = this.$cookies.get("x-csrf-token");
            let data = {
                name: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phoneNumber: this.phoneNumber,
                barrelSaunaCount: this.barrelSaunaCount,
                peoplesCount: this.peoplesCount,
                fromDate: this.bookingDate.start,
                toDate: this.bookingDate.end,
                arrivalTime: this.arrivalTime || "15:00",
                quotedAmount: this.bookingAmount,
                campaignCode: this.campaignCode,
                lang: this.$i18n.locale,
                reCaptchaToken: this.reCaptchaToken,
                _csrf: cookie,
            };
            this.bookingsLoaded = false;
            this.$http().post(url, data)
                .then(() => {
                    this.$toast.open({
                        type: 'success',
                        message: this.$i18n.t('We have successfully received your reservation'),
                        duration: 4000,
                        onDismiss: this.exitBooking
                    });
                    this.getBookings();
                })
                .catch(() => {
                    this.$toast.open({
                        type: 'error',
                        message: this.$i18n.t('We are sorry, but we could not make your reservation'),
                        duration: 8000,
                    });
                    this.$refs.recaptcha.reset();
                })
                .finally(() => {
                    this.bookingsLoaded = true;
                })
        },
        clearFirstName() {
            this.firstName = '';
        },
        clearLastName() {
            this.lastName = '';
        },
        clearEmail() {
            this.email = '';
        },
        clearPhone() {
            this.phoneNumber = '';
        },
        clearDates() {
            this.bookingDate = {
                start: undefined,
                end: undefined,
            };
        },
        clearFields() {
            this.clearFirstName();
            this.clearLastName();
            this.clearEmail();
            this.clearPhone();
            this.clearDates();
        },
        preventClick(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        agreeWithReservation() {
            this.agreedReservationRules = "true";
        },
        agreeWithRules() {
            this.agreedHouseRules = "true";
        },
        dayClick(event) {
            if (event.isDisabled !== false) {
                return;
            }

            this.dblCounter++;
            if (this.dblCounter >= 2) {
                this.$toast.error( this.$i18n.t('Do not use DOUBLE clicks. Use a SINGLE click to select a date'), {duration: 5000});
            }
            let dblTimeout = setTimeout(() => {
                this.dblCounter = 0;
                clearTimeout(dblTimeout);
            },600);

            this.dateStartedDate = event.range.start;
        },
        getHouseRules() {
            let url = `/houserules/${this.$i18n.locale}`;
            this.$http().get(url)
                .then((response) => {
                    this.houseRules = response.data;
                })
        },
        getBookingConditions() {
            let url = `/bookingconditions/${this.$i18n.locale}`;
            this.$http().get(url)
                .then((response) => {
                    this.bookingConditions = response.data;
                })
        },
        reCaptchaVerify(response) {
            this.reCaptchaToken = response || undefined;
            this.reCaptchaVerified = true;
            this.timeRemaining = 60;
            let countdownTimer = setInterval(() => {
                if (this.stopTimer || this.timeRemaining < 0) {
                    clearInterval(countdownTimer);
                    this.timeRemaining = 0;
                    this.resetReCaptcha();
                } else {
                    this.timeRemaining--;
                }
            }, 1000);
        },
        resetReCaptcha() {
            this.reCaptchaToken = '';
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
            }
            this.reCaptchaVerified = false;
        },
        clearTimer() {
            this.stopTimer = true;
        }
    },
    computed: {
        numOfNights()  {
            if (!this.bookingDate || !this.bookingDate.start || !this.bookingDate.end) {
                return 0;
            }
            let startDate = this.$moment(this.bookingDate.start);
            let endDate = this.$moment(this.bookingDate.end);

            return mathAbs(startDate.diff(endDate, 'days'));
        },
        showFromDate() {
            return this.bookingDate !== undefined && this.bookingDate.start !== undefined
            ? this.$moment(this.bookingDate.start).format(this.modelConfig.mask)
                : '';
        },
        showToDate() {
            return this.bookingDate !== undefined && this.bookingDate.end !== undefined
            ? this.$moment(this.bookingDate.end).format(this.modelConfig.mask)
                : '';
        },
        disabledDates() {
            return !this.dateSelectStarted ? this.currentBookings : [];
        },
        maxDate() {
            if (!this.dateSelectStarted) {
                return this.maxDateByConfig;
            }

            let futureBookings = this.currentBookings.filter((item) => {
                return item.start >= this.$moment(this.dateStartedDate).toDate();
            });

            return futureBookings.length ? this.$moment(futureBookings[0].start).toDate() : this.maxDateByConfig
        },
        minDate() {
            return !this.dateSelectStarted ? new Date() : this.dateStartedDate;
        },
        bookingAmount() {
            return (this.quote.totalAmount);
        },
    }
}
